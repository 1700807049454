import { createAction, props } from '@ngrx/store';
import { BasicResponse, ListSchema, NameValueItem, ResponseWrapper } from 'common';
import { FacebookAudiencePushItem, FacebookChart, MetaAuthResponse, RequestedFacebookAudienceRequest } from './meta-ads-manager.interface';
import { HttpErrorResponse } from '@angular/common/http';

export enum IntegrationsMetaAdsManagerInnerActionTypes {
    GetPastAudiencesSuccess = '[Integrations] Get Past Audiences Success',
    GetPastAudiencesError = '[Integrations] Get Past Audiences Error',
    SetMetaAccessInfo = '[Integrations] Set Meta Access Info',
    GetFacebookAdAccountsSuccess = '[Integrations] Get Facebook Ad Accounts Success',
    GetFacebookAdAccountsError = '[Integrations] Get Facebook Ad Accounts Error',
    SendAudienceToFacebookSuccess = '[Meta Ads] Send Audience To Facebook Success',
    SendAudienceToFacebookError = '[Meta Ads] Send Audience To Facebook Error',
    UpdateFacebookAudienceSuccess = '[Meta Ads] Update Facebook Audience Success',
    UpdateFacebookAudienceError = '[Meta Ads] Update Facebook Audience Error',
    ViewPerformanceSuccess = '[Meta Ads] View Performance Success',
    ViewPerformanceError = '[Meta Ads] View Performance Error',
}

export enum IntegrationsMetaAdsManagerHttpActionTypes {
    SetSelectedAdAccount = '[Integrations] Set Selected Ad Account',
    GetPastAudiences = '[Integrations] Get Past Audiences',
    GetFacebookAdAccounts = '[Integrations] Get Facebook Ad Accounts',
    SendAudienceToFacebook = '[Meta Ads] Send Audience To Facebook',
    UpdateFacebookAudience = '[Meta Ads] Update Facebook Audience',
    ViewPerformance = '[Meta Ads] View Performance',
}

// ===== HTTP =====
export const SetSelectedAdAccount = createAction(IntegrationsMetaAdsManagerHttpActionTypes.SetSelectedAdAccount, props<{ payload: string }>());
export const GetPastAudiences = createAction(IntegrationsMetaAdsManagerHttpActionTypes.GetPastAudiences, props<{ payload: { searchText?: string, offset?: number, numResults?: number, order?: string, sortBy?: string } }>());
export const GetFacebookAdAccounts = createAction(IntegrationsMetaAdsManagerHttpActionTypes.GetFacebookAdAccounts, props<{ payload: { environmentId: number, accessToken: string, userId: string } }>());
export const SendAudienceToFacebook = createAction(IntegrationsMetaAdsManagerHttpActionTypes.SendAudienceToFacebook, props<{ payload: RequestedFacebookAudienceRequest }>());
export const UpdateFacebookAudience = createAction(IntegrationsMetaAdsManagerHttpActionTypes.UpdateFacebookAudience, props<{ payload: { facebookAudiencePushId: number, action: string } }>());
export const ViewPerformance = createAction(IntegrationsMetaAdsManagerHttpActionTypes.ViewPerformance, props<{ payload: { facebookAudiencePushId: number, accessToken: string, adAccount: number } }>());

// ===== INNER =====
export const GetPastAudiencesSuccess = createAction(IntegrationsMetaAdsManagerInnerActionTypes.GetPastAudiencesSuccess, props<{ payload: ResponseWrapper<ListSchema<FacebookAudiencePushItem>> }>());
export const GetPastAudiencesError = createAction(IntegrationsMetaAdsManagerInnerActionTypes.GetPastAudiencesError, props<{ payload: BasicResponse | HttpErrorResponse }>());
export const SendAudienceToFacebookSuccess = createAction(IntegrationsMetaAdsManagerInnerActionTypes.SendAudienceToFacebookSuccess, props<{ payload: ResponseWrapper<BasicResponse> }>());
export const SendAudienceToFacebookError = createAction(IntegrationsMetaAdsManagerInnerActionTypes.SendAudienceToFacebookError, props<{ error: BasicResponse }>());
export const SetMetaAccessInfo = createAction(IntegrationsMetaAdsManagerInnerActionTypes.SetMetaAccessInfo, props<{ payload: MetaAuthResponse }>());
export const GetFacebookAdAccountsSuccess = createAction(IntegrationsMetaAdsManagerInnerActionTypes.GetFacebookAdAccountsSuccess, props<{ payload: NameValueItem[] }>());
export const GetFacebookAdAccountsError = createAction(IntegrationsMetaAdsManagerInnerActionTypes.GetFacebookAdAccountsError);
export const UpdateFacebookAudienceSuccess = createAction(IntegrationsMetaAdsManagerInnerActionTypes.UpdateFacebookAudienceSuccess, props<{ payload: ResponseWrapper<BasicResponse> }>());
export const UpdateFacebookAudienceError = createAction(IntegrationsMetaAdsManagerInnerActionTypes.UpdateFacebookAudienceError, props<{ payload: BasicResponse }>());
export const ViewPerformanceSuccess = createAction(IntegrationsMetaAdsManagerInnerActionTypes.ViewPerformanceSuccess, props<{ payload: ResponseWrapper<Array<FacebookChart>> }>());
export const ViewPerformanceError = createAction(IntegrationsMetaAdsManagerInnerActionTypes.ViewPerformanceError, props<{ payload: BasicResponse }>());