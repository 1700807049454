import { createAction, props } from '@ngrx/store';
import { BasicResponse, RequestedPaginationSchema, ResponseWrapper } from 'common';
import { IntegrationDashboardSchema, IntegrationDeltaExtractSetup, IntegrationExtractFileResponse, IntegrationFileFormatResponse, IntegrationHelperResponse, IntegrationOutputCriteriaGroupItem, ManageFileFormatPayload, MappedProduct, SaveDeltaJobDetailPayload } from './delta-ftp.interface';

export enum IntegrationsDeltFtpInnerActionTypes {
    GetDeltaJobsSuccess = '[Delta To FTP] Get Delta Jobs Success',
    GetDeltaJobsError = '[Delta To FTP] Get Delta Jobs Error',
    GetDeltaJobDetailsSuccess = '[Delta To FTP] Get Delta Job Details Success',
    GetDeltaJobDetailsError = '[Delta To FTP] Get Delta Job Details Error',
    ResetDeltaFullFileSuccess = '[Delta To FTP] Reset Delta Full File Success',
    ResetDeltaFullFileError = '[Delta To FTP] Reset Delta Full File Error',
    SaveJobDetailsSuccess = '[Delta To FTP] Save Job Details Success',
    SaveJobDetailsError = '[Delta To FTP] Save Job Details Error',
    ViewMappedFieldsSuccess = '[Delta To FTP] View Mapped Fields Success',
    ViewMappedFieldsError = '[Delta To FTP] View Mapped Fields Error',
    GetJobFilesSuccess = '[Delta To FTP] Get Job Files Success',
    GetJobFilesError = '[Delta To FTP] Get Job Files Error',
    GetFileFormatSuccess = '[Delta To FTP] Get File Format Success',
    GetFileFormatError = '[Delta To FTP] Get File Format Error',
    RemoveFileFormatSuccess = '[Delta To FTP] Remove File Format Success',
    RemoveFileFormatError = '[Delta To FTP] Remove File Format Error',
    GetAvailableFileFieldsSuccess = '[Delta To FTP] Get Available File Fields Success',
    GetAvailableFileFieldsError = '[Delta To FTP] Get Available File Fields Error',
    GetFileFormatDataSuccess = '[Delta To FTP] Get File Format Data Success',
    GetFileFormatDataError = '[Delta To FTP] Get File Format Data Error',
    GetDefaultFileFormatFieldsSuccess = '[Delta To FTP] Get Default File Format Fields Success',
    GetDefaultFileFormatFieldsError = '[Delta To FTP] Get Default File Format Fields Error',
    ManageFileFormatSuccess = '[Delta To FTP] Manage File Format Success',
    ManageFileFormatError = '[Delta To FTP] Manage File Format Error',
    UpdateCurrentDeltaFtpJobState = '[Delta To FTP] Update Current Delta Ftp Job State',
    UpdateDeltaJobStatusSuccess = '[Delta To FTP] Update Delta Job Status Success',
    UpdateDeltaJobStatusError = '[Delta To FTP] Update Delta Job Status Error',
    UnsavedFtpChange = '[Delta To FTP] Unsaved Ftp Change',
}

export enum IntegrationsDeltFtpHttpActionTypes {
    GetDeltaJobs = '[Delta To FTP] Get Delta Jobs',
    GetDeltaJobDetails = '[Delta To FTP] Get Delta Job Details',
    ResetDeltaFullFile = '[Delta To FTP] Reset Delta Full File',
    SaveJobDetails = '[Delta To FTP] Save Job Details',
    ViewMappedFields = '[Delta To FTP] View Mapped Fields',
    GetJobFiles = '[Delta To FTP] Get Job Files',
    GetFileFormat = '[Delta To FTP] Get File Format',
    RemoveFileFormat = '[Delta To FTP] Remove File Format',
    GetAvailableFileFields = '[Delta To FTP] Get Available File Fields',
    GetFileFormatData = '[Delta To FTP] Get File Format Data',
    GetDefaultFileFormatFields = '[Delta To FTP] Get Default File Format Fields',
    ManageFileFormat = '[Delta To FTP] Manage File Format',
    UpdateDeltaJobStatus = '[Delta To FTP] Update Delta Job Status',
}

// ===== HTTP =====
export const GetDeltaJobs = createAction(IntegrationsDeltFtpHttpActionTypes.GetDeltaJobs, props<{ payload: { searchText?: string, offset?: number, numResult?: number, sortBy?: string, order?: string } }>());
export const GetDeltaJobDetails = createAction(IntegrationsDeltFtpHttpActionTypes.GetDeltaJobDetails, props<{ payload: { extractSetupId?: number, numResult?: number, offset?: number, order?: string, sortBy?: string, reloadProductsTable?: boolean } }>());
export const ResetDeltaFullFile = createAction(IntegrationsDeltFtpHttpActionTypes.ResetDeltaFullFile, props<{ payload: { extractSetupId: number } }>());
export const SaveJobDetails = createAction(IntegrationsDeltFtpHttpActionTypes.SaveJobDetails, props<{ payload: SaveDeltaJobDetailPayload, currentRoute?: string, pageLoadPoolSelectionCriteriaName?: string }>());
export const ViewMappedFields = createAction(IntegrationsDeltFtpHttpActionTypes.ViewMappedFields, props<{ payload: { pageLoadFileGroupId: number, productId: number } }>());
export const GetJobFiles = createAction(IntegrationsDeltFtpHttpActionTypes.GetJobFiles, props<{ payload: { extractSetupId: number, pageLoadFileGroupId: number } }>());
export const GetFileFormat = createAction(IntegrationsDeltFtpHttpActionTypes.GetFileFormat, props<{ payload: { outputCriteriaId: number } }>());
export const RemoveFileFormat = createAction(IntegrationsDeltFtpHttpActionTypes.RemoveFileFormat, props<{ payload: { extractSetupId: number, pageLoadOutputCriteriaId: number, pageLoadFileId: number }, reloadPayload: { extractSetupId?: number, numResult?: number, offset?: number, order?: string, sortBy?: string, reloadProductsTable?: boolean } }>());
export const GetAvailableFileFields = createAction(IntegrationsDeltFtpHttpActionTypes.GetAvailableFileFields);
export const GetFileFormatData = createAction(IntegrationsDeltFtpHttpActionTypes.GetFileFormatData, props<{ payload: { extractSetupId: number, pageLoadProductId: number, outputCriteriaId?: number } }>());
export const GetDefaultFileFormatFields = createAction(IntegrationsDeltFtpHttpActionTypes.GetDefaultFileFormatFields);
export const ManageFileFormat = createAction(IntegrationsDeltFtpHttpActionTypes.ManageFileFormat, props<{ payload: ManageFileFormatPayload, reloadPayload?: { extractSetupId?: number, numResult?: number, offset?: number, order?: string, sortBy?: string, reloadProductsTable?: boolean } }>());
export const UpdateDeltaJobStatus = createAction(IntegrationsDeltFtpHttpActionTypes.UpdateDeltaJobStatus, props<{ payload: { extractSetupId: number, statusCode: number, reload?: RequestedPaginationSchema } }>());
// ===== INNER =====
export const GetDeltaJobsSuccess = createAction(IntegrationsDeltFtpInnerActionTypes.GetDeltaJobsSuccess, props<{ payload: ResponseWrapper<IntegrationDashboardSchema> }>());
export const GetDeltaJobsError = createAction(IntegrationsDeltFtpInnerActionTypes.GetDeltaJobsError, props<{ payload: BasicResponse }>());
export const GetDeltaJobDetailsSuccess = createAction(IntegrationsDeltFtpInnerActionTypes.GetDeltaJobDetailsSuccess, props<{ payload: ResponseWrapper<IntegrationHelperResponse & {reloadProductsTable?: boolean;}> }>());
export const GetDeltaJobDetailsError = createAction(IntegrationsDeltFtpInnerActionTypes.GetDeltaJobDetailsError, props<{ payload: BasicResponse, reloadProductsTable?: boolean }>());
export const ResetDeltaFullFileSuccess = createAction(IntegrationsDeltFtpInnerActionTypes.ResetDeltaFullFileSuccess, props<{ payload: BasicResponse }>());
export const ResetDeltaFullFileError = createAction(IntegrationsDeltFtpInnerActionTypes.ResetDeltaFullFileError, props<{ payload: BasicResponse }>());
export const SaveJobDetailsSuccess = createAction(IntegrationsDeltFtpInnerActionTypes.SaveJobDetailsSuccess, props<{ payload: ResponseWrapper<IntegrationDeltaExtractSetup & {pageLoadPoolSelectionCriteriaName: string;}> }>());
export const SaveJobDetailsError = createAction(IntegrationsDeltFtpInnerActionTypes.SaveJobDetailsError, props<{ payload: BasicResponse }>());
export const ViewMappedFieldsSuccess = createAction(IntegrationsDeltFtpInnerActionTypes.ViewMappedFieldsSuccess, props<{ payload: ResponseWrapper<MappedProduct> }>());
export const ViewMappedFieldsError = createAction(IntegrationsDeltFtpInnerActionTypes.ViewMappedFieldsError, props<{ payload: BasicResponse }>());
export const GetJobFilesSuccess = createAction(IntegrationsDeltFtpInnerActionTypes.GetJobFilesSuccess, props<{ payload: ResponseWrapper<IntegrationHelperResponse> }>());
export const GetJobFilesError = createAction(IntegrationsDeltFtpInnerActionTypes.GetJobFilesError, props<{ payload: BasicResponse }>());
export const GetFileFormatSuccess = createAction(IntegrationsDeltFtpInnerActionTypes.GetFileFormatSuccess, props<{ payload: ResponseWrapper<IntegrationFileFormatResponse> }>());
export const GetFileFormatError = createAction(IntegrationsDeltFtpInnerActionTypes.GetFileFormatError, props<{ payload: BasicResponse }>());
export const RemoveFileFormatSuccess = createAction(IntegrationsDeltFtpInnerActionTypes.RemoveFileFormatSuccess);
export const RemoveFileFormatError = createAction(IntegrationsDeltFtpInnerActionTypes.RemoveFileFormatError, props<{ payload: BasicResponse }>());
export const GetAvailableFileFieldsSuccess = createAction(IntegrationsDeltFtpInnerActionTypes.GetAvailableFileFieldsSuccess, props<{ payload: ResponseWrapper<IntegrationOutputCriteriaGroupItem> }>());
export const GetAvailableFileFieldsError = createAction(IntegrationsDeltFtpInnerActionTypes.GetAvailableFileFieldsError, props<{ payload: BasicResponse }>());
export const GetFileFormatDataSuccess = createAction(IntegrationsDeltFtpInnerActionTypes.GetFileFormatDataSuccess, props<{ payload: ResponseWrapper<IntegrationFileFormatResponse> }>());
export const GetFileFormatDataError = createAction(IntegrationsDeltFtpInnerActionTypes.GetFileFormatDataError, props<{ payload: BasicResponse }>());
export const GetDefaultFileFormatFieldsSuccess = createAction(IntegrationsDeltFtpInnerActionTypes.GetDefaultFileFormatFieldsSuccess, props<{ payload: ResponseWrapper<IntegrationFileFormatResponse> }>());
export const GetDefaultFileFormatFieldsError = createAction(IntegrationsDeltFtpInnerActionTypes.GetDefaultFileFormatFieldsError, props<{ payload: BasicResponse }>());
export const ManageFileFormatSuccess = createAction(IntegrationsDeltFtpInnerActionTypes.ManageFileFormatSuccess, props<{ payload: ResponseWrapper<IntegrationExtractFileResponse> }>());
export const ManageFileFormatError = createAction(IntegrationsDeltFtpInnerActionTypes.ManageFileFormatError, props<{ payload: BasicResponse }>());
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const UpdateCurrentDeltaFtpJobState = createAction(IntegrationsDeltFtpInnerActionTypes.UpdateCurrentDeltaFtpJobState, props<{ payload: any }>());
export const UpdateDeltaJobStatusSuccess = createAction(IntegrationsDeltFtpInnerActionTypes.UpdateDeltaJobStatusSuccess);
export const UpdateDeltaJobStatusError = createAction(IntegrationsDeltFtpInnerActionTypes.UpdateDeltaJobStatusError, props<{ payload: BasicResponse }>());
export const UnsavedFtpChange = createAction(IntegrationsDeltFtpInnerActionTypes.UnsavedFtpChange, props<{payload: boolean}>());
