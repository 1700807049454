import { createAction, props } from '@ngrx/store';
import { BasicResponse, ListSchema, ResponseWrapper } from 'common';
import { AdrollAccessInfo, AdrollAudience, AdrollAuthorization } from './adroll.interface';
import { AdrollAdAccount } from '../interfaces';

export enum IntegrationsAdrollInnerActionTypes {
    GetAdrollRecentAudiencesSuccess = '[Adroll] Get Adroll Recent Audiences Success',
    GetAdrollRecentAudiencesError = '[Adroll] Get Adroll Recent Audiences Error',
    PushAudienceToAdrollSuccess = '[Adroll] Push Audience To Adroll Success',
    PushAudienceToAdrollError = '[Adroll] Push Audience To Adroll Error',
    GetAdvertiserAccountsSuccess = '[Adroll] Get Advertiser Accounts Success',
    GetAdvertiserAccountsError = '[Adroll] Get Advertiser Accounts Error',
    GetAdrollAccessTokenSuccess = '[Adroll] Get Adroll Access Token Success',
    GetAdrollAccessTokenError = '[Adroll] Get Adroll Access Token Error',
    GetAdrollAuthInfoSuccess = '[Adroll] Get Adroll Auth Info Success',
    GetAdrollAuthInfoError = '[Adroll] Get Adroll Auth Info Error',
    GetAdrollAccessTokenFromRefreshTokenSuccess = '[Adroll] Get Adroll Access Token From Refresh Token Success',
    GetAdrollAccessTokenFromRefreshTokenError = '[Adroll] Get Adroll Access Token From Refresh Token Error',
}

export enum IntegrationsAdrollHttpActionTypes {
    GetAdrollRecentAudiences = '[Adroll] Get Adroll Recent Audiences',
    PushAudienceToAdroll = '[Adroll] Push Audience To Adroll',
    GetAdvertiserAccounts = '[Adroll] Get Advertiser Accounts',
    GetAdrollAccessToken = '[Adroll] Get Adroll Access Token',
    GetAdrollAuthInfo = '[Adroll] Get Adroll Auth Info',
    GetAdrollAccessTokenFromRefreshToken = '[Adroll] Get Adroll Access Token From Refresh Token',
}

// ===== HTTP =====
export const GetAdrollRecentAudiences = createAction(IntegrationsAdrollHttpActionTypes.GetAdrollRecentAudiences, props<{ payload: { name?: string, numResults?: number, offset?: number, sortBy?: string, order?: string } }>());
export const PushAudienceToAdroll = createAction(IntegrationsAdrollHttpActionTypes.PushAudienceToAdroll, props<{ payload: { audienceName: string, advertiserId: string, selectionCriteriaId: number, refreshAbAudienceBeforeSending: boolean, refreshToken: string, accessToken: string } }>());
export const GetAdvertiserAccounts = createAction(IntegrationsAdrollHttpActionTypes.GetAdvertiserAccounts, props<{ payload: { accessToken: string, refreshToken?: string } }>());
export const GetAdrollAccessToken = createAction(IntegrationsAdrollHttpActionTypes.GetAdrollAccessToken, props<{ payload: { code: string } }>());
export const GetAdrollAuthInfo = createAction(IntegrationsAdrollHttpActionTypes.GetAdrollAuthInfo);
export const GetAdrollAccessTokenFromRefreshToken = createAction(IntegrationsAdrollHttpActionTypes.GetAdrollAccessTokenFromRefreshToken, props<{ payload: { refreshToken: string } }>());

// ===== INNER =====
export const GetAdrollRecentAudiencesSuccess = createAction(IntegrationsAdrollInnerActionTypes.GetAdrollRecentAudiencesSuccess, props<{ payload: ResponseWrapper<ListSchema<AdrollAudience>> }>());
export const GetAdrollRecentAudiencesError = createAction(IntegrationsAdrollInnerActionTypes.GetAdrollRecentAudiencesError, props<{ payload: BasicResponse }>());
export const PushAudienceToAdrollSuccess = createAction(IntegrationsAdrollInnerActionTypes.PushAudienceToAdrollSuccess, props<{ payload: ResponseWrapper<BasicResponse> }>());
export const PushAudienceToAdrollError = createAction(IntegrationsAdrollInnerActionTypes.PushAudienceToAdrollError, props<{ payload: BasicResponse }>());
export const GetAdvertiserAccountsSuccess = createAction(IntegrationsAdrollInnerActionTypes.GetAdvertiserAccountsSuccess, props<{ payload: ResponseWrapper<AdrollAdAccount[]> }>());
export const GetAdvertiserAccountsError = createAction(IntegrationsAdrollInnerActionTypes.GetAdvertiserAccountsError, props<{ payload: BasicResponse }>());
export const GetAdrollAccessTokenSuccess = createAction(IntegrationsAdrollInnerActionTypes.GetAdrollAccessTokenSuccess, props<{ payload: ResponseWrapper<AdrollAccessInfo> }>());
export const GetAdrollAuthInfoSuccess = createAction(IntegrationsAdrollInnerActionTypes.GetAdrollAuthInfoSuccess, props<{ payload: ResponseWrapper<AdrollAuthorization> }>());
export const GetAdrollAuthInfoError = createAction(IntegrationsAdrollInnerActionTypes.GetAdrollAuthInfoError, props<{ payload: BasicResponse }>());
export const GetAdrollAccessTokenFromRefreshTokenSuccess = createAction(IntegrationsAdrollInnerActionTypes.GetAdrollAccessTokenFromRefreshTokenSuccess, props<{ payload: ResponseWrapper<AdrollAccessInfo> }>());
export const GetAdrollAccessTokenFromRefreshTokenError = createAction(IntegrationsAdrollInnerActionTypes.GetAdrollAccessTokenFromRefreshTokenError, props<{ payload: BasicResponse }>());