import { createAction, props } from '@ngrx/store';
import { GetFileListCountPayload, GetFileListPayload, GetShopifyStoresPayload, ProductI, SaveStorePayload, ShopifyState, ShopifyStoreStatus, StoreDetailI } from '@integrations/state/shopify/shopify.interface';
import { BasicResponse, ListSchema } from 'common';
import { DeploymentType } from 'common';
import { MessageType } from '@self-service/state/message-types/message-types.interface';
import { DataFile, FileContentsResponse, FileMappingTemplate } from '@data-loader/app/state/interfaces';

export enum IntegrationsShopifyInnerActionTypes {
    GetShopifyStoresSuccess = '[Shopify] Get list of all Shopify stores Success',
    GetShopifyStoresError = '[Shopify] Get list of all Shopify stores Error',
    GetShopifyStoreSuccess = '[Shopify] Get a single Shopify store Success',
    GetShopifyStoreError = '[Shopify] Get a single Shopify store Error',
    SaveShopifyStoreSuccess = '[Shopify] Save a Shopify store Success',
    SaveShopifyStoreError = '[Shopify] Save a Shopify store Error',
    UpdateShopifyStoreStatusSuccess = '[Shopify] Update Shopify store status Success',
    UpdateShopifyStoreStatusError = '[Shopify] Update Shopify store status Error',
    GetShopifyStoreByWebhookIdSuccess = '[Shopify] Get a single Shopify store by webhook id Success',
    GetShopifyStoreByWebhookIdError = '[Shopify] Get a single Shopify store by webhook id Error',
    GetMessageTypesSuccess = '[Common] Get list of all SMS message types Success',
    GetMessageTypesError = '[Common] Get list of all SMS message types Error',
    GetDeploymentTypesSuccess = '[Common] Get list of all deployment types Success',
    GetDeploymentTypesError = '[Common] Get list of all deployment types Error',
    GetProductsSuccess = '[Common] Get list of all Shopify products Success',
    GetProductsError = '[Common] Get list of all Shopify products Error',
    GetFileListSuccess = '[Shopify] Get list of all records for Shopify store Success',
    GetFileListError = '[Shopify] Get list of all records for Shopify store Error',
    GetFileListCountSuccess = '[Shopify] Get count of all records for Shopify store Success',
    GetFileListCountError = '[Shopify] Get count of all records for Shopify store Error',
    GetFileListDetailSuccess = '[Shopify] Get detail for a shopify record Success',
    GetFileListDetailError = '[Shopify] Get detail for a shopify record Error',
    GetTemplateSuccess = '[Common] Get template information for shopify record Success',
    GetTemplateError = '[Common] Get template information for shopify record Error'
}

export enum IntegrationsShopifyHttpActionTypes {
    GetShopifyStores = '[Shopify] Get list of all Shopify stores',
    GetShopifyStore = '[Shopify] Get a single Shopify store',
    SaveShopifyStore = '[Shopify] Save a Shopify store',
    UpdateShopifyStoreStatus = '[Shopify] Update Shopify store status 1=active, 0=inactive',
    GetShopifyStoreByWebhookId = '[Shopify] Get a single Shopify store by webhook id',
    ClearShopifyStoreValue = '[Shopify] Clear Shopify store value',
    GetMessageTypes = '[Common] Get list of all SMS message types',
    GetDeploymentTypes = '[Common] Get list of all deployment types',
    GetProducts = '[Common] Get list of all Shopify products',
    GetFileList = '[Shopify] Get list of all records for Shopify store',
    GetFileListCount = '[Shopify] Get count of all records for Shopify store',
    GetFileDetail = '[Shopify] Get detail for a shopify record',
    GetTemplate = '[Common] Get template information for shopify record'
}

// ===== HTTP =====
export const GetShopifyStores = createAction(IntegrationsShopifyHttpActionTypes.GetShopifyStores, (props: GetShopifyStoresPayload = {}) => ({props}));
export const GetShopifyStore = createAction(IntegrationsShopifyHttpActionTypes.GetShopifyStore, props<{ payload: {shopifyStoreId: number} }>());
export const SaveShopifyStore = createAction(IntegrationsShopifyHttpActionTypes.SaveShopifyStore, props<{ payload: SaveStorePayload }>());
export const UpdateShopifyStoreStatus = createAction(IntegrationsShopifyHttpActionTypes.UpdateShopifyStoreStatus, props<{ payload: {shopifyStoreId: number, statusCode: ShopifyStoreStatus} }>());
export const GetShopifyStoreByWebhookId = createAction(IntegrationsShopifyHttpActionTypes.GetShopifyStoreByWebhookId, props<{ payload: {webhookId: number} }>());
export const GetMessageTypes = createAction(IntegrationsShopifyHttpActionTypes.GetMessageTypes, (props: { useCache: boolean } = { useCache: false }) => ({props}));
export const GetDeploymentTypes = createAction(IntegrationsShopifyHttpActionTypes.GetDeploymentTypes, (props: { useCache: boolean } = { useCache: false }) => ({props}));
export const GetProducts = createAction(IntegrationsShopifyHttpActionTypes.GetProducts, (props: { useCache: boolean } = { useCache: false }) => ({props}));
export const GetFileList = createAction(IntegrationsShopifyHttpActionTypes.GetFileList, props<{ payload: GetFileListPayload }>());
export const GetFileListCount = createAction(IntegrationsShopifyHttpActionTypes.GetFileListCount, props<{ payload: GetFileListCountPayload }>());
export const GetFileDetail = createAction(IntegrationsShopifyHttpActionTypes.GetFileDetail, props<{ payload: { fileId: number } }>());
export const GetTemplate = createAction(IntegrationsShopifyHttpActionTypes.GetTemplate, props<{ payload: { fileMappingId: number } }>());

// ===== INNER =====
export const GetShopifyStoresSuccess = createAction(IntegrationsShopifyInnerActionTypes.GetShopifyStoresSuccess, props<{ payload: ListSchema<StoreDetailI> }>());
export const GetShopifyStoresError = createAction(IntegrationsShopifyInnerActionTypes.GetShopifyStoresError);
export const GetShopifyStoreSuccess = createAction(IntegrationsShopifyInnerActionTypes.GetShopifyStoreSuccess, props<{ payload: StoreDetailI }>());
export const GetShopifyStoreError = createAction(IntegrationsShopifyInnerActionTypes.GetShopifyStoreError);
export const SaveShopifyStoreSuccess = createAction(IntegrationsShopifyInnerActionTypes.SaveShopifyStoreSuccess, props<{ payload: BasicResponse }>());
export const SaveShopifyStoreError = createAction(IntegrationsShopifyInnerActionTypes.SaveShopifyStoreError);
export const UpdateShopifyStoreStatusSuccess = createAction(IntegrationsShopifyInnerActionTypes.UpdateShopifyStoreStatusSuccess, props<{ payload: StoreDetailI }>());
export const UpdateShopifyStoreStatusError = createAction(IntegrationsShopifyInnerActionTypes.UpdateShopifyStoreStatusError);
export const GetShopifyStoreByWebhookIdSuccess = createAction(IntegrationsShopifyInnerActionTypes.GetShopifyStoreByWebhookIdSuccess, props<{ payload: StoreDetailI }>());
export const GetShopifyStoreByWebhookIdError = createAction(IntegrationsShopifyInnerActionTypes.GetShopifyStoreByWebhookIdError);
export const GetMessageTypesSuccess = createAction(IntegrationsShopifyInnerActionTypes.GetMessageTypesSuccess, props<{ payload: MessageType[] }>());
export const GetMessageTypesError = createAction(IntegrationsShopifyInnerActionTypes.GetMessageTypesError);
export const GetDeploymentTypesSuccess = createAction(IntegrationsShopifyInnerActionTypes.GetDeploymentTypesSuccess, props<{ payload: DeploymentType[] }>());
export const GetDeploymentTypesError = createAction(IntegrationsShopifyInnerActionTypes.GetDeploymentTypesError);
export const GetProductsSuccess = createAction(IntegrationsShopifyInnerActionTypes.GetProductsSuccess, props<{ payload: ListSchema<ProductI> }>());
export const GetProductsError = createAction(IntegrationsShopifyInnerActionTypes.GetProductsError);
export const GetFileListSuccess = createAction(IntegrationsShopifyInnerActionTypes.GetFileListSuccess, props<{ payload: DataFile[] }>());
export const GetFileListError = createAction(IntegrationsShopifyInnerActionTypes.GetFileListError, props<{ payload: BasicResponse }>());
export const GetFileListCountSuccess = createAction(IntegrationsShopifyInnerActionTypes.GetFileListCountSuccess, props<{ payload: number }>());
export const GetFileListCountError = createAction(IntegrationsShopifyInnerActionTypes.GetFileListCountError, props<{ payload: BasicResponse }>());
export const GetFileListDetailSuccess = createAction(IntegrationsShopifyInnerActionTypes.GetFileListDetailSuccess, props<{ payload: FileContentsResponse }>());
export const GetFileListDetailError = createAction(IntegrationsShopifyInnerActionTypes.GetFileListDetailError);
export const GetTemplateSuccess = createAction(IntegrationsShopifyInnerActionTypes.GetTemplateSuccess, props<{ payload: FileMappingTemplate }>());
export const GetTemplateError = createAction(IntegrationsShopifyInnerActionTypes.GetTemplateError);

// ===== CLEAR =====
export const ClearShopifyStoreValue = createAction(IntegrationsShopifyHttpActionTypes.ClearShopifyStoreValue, props<{storeValue: keyof ShopifyState}>());
