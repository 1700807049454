import { createAction, props } from '@ngrx/store';
import { BasicResponse, ResponseWrapper } from 'common';
import { GoogleAdManagerDomainGroupItem, GoogleAdManagerDomainSetupResponse, GoogleAdManagerExistingKeyResponse, GoogleAdManagerExistingValuesItem, GoogleAdManagerExternalSegmentDetailsResponse, GoogleAdManagerRestriction, JavascriptSetup } from './google-ad-manager.interface';

export enum IntegrationsGoogleAdManagerInnerActionTypes {
    GetGoogleJavascriptSetupSuccess = '[Google] Get Google Javascript Setup Success',
    GetGoogleJavascriptSetupError = '[Google] Get Google Javascript Setup Error',
    GetSelectedGoogleKeysSuccess = '[Google] Get Selected Google Keys Success',
    GetSelectedGoogleKeysError = '[Google] Get Selected Google Keys Error',
    RequestDemoSuccess = '[Google] Request Demo Success',
    RequestDemoError = '[Google] Request Demo Error',
    GetDomainRestrictionsSuccess = '[Google] Get Domain Restrictions Success',
    GetDomainRestrictionsError = '[Google] Get Domain Restrictions Error',
    GetSelectedDomainRestrictionSuccess = '[Google] Get Selected Domain Restriction Success',
    GetSelectedDomainRestrictionError = '[Google] Get Selected Domain Restriction Error',
    DeleteDomainRestrictionSuccess = '[Google] Delete Domain Restriction Success',
    DeleteDomainRestrictionError = '[Google] Delete Domain Restriction Error',
    EditDomainRestrictionSuccess = '[Google] Edit Domain Restriction Success',
    EditDomainRestrictionError = '[Google] Edit Domain Restriction Error',
    CreateDomainRestrictionSuccess = '[Google] Create Domain Restriction Success',
    CreateDomainRestrictionError = '[Google] Create Domain Restriction Error',
    GetAllKeyValuesSuccess = '[Google] Get All Key Values Success',
    GetAllKeyValuesError = '[Google] Get All Key Values Error',
    GetSelectedKeyDetailsSuccess = '[Google] Get Selected Key Value Success',
    GetSelectedKeyDetailsError = '[Google] Get Selected Key Value Error',
    DeleteKeyValueSuccess = '[Google] Delete Key Value Success',
    DeleteKeyValueError = '[Google] Delete Key Value Error',
    EditKeyDetailsSuccess = '[Google] Edit Key Value Success',
    EditKeyDetailsError = '[Google] Edit Key Value Error',
    CreateNewKeyValueSuccess = '[Google] Create New Key Value Success',
    CreateNewKeyValueError = '[Google] Create New Key Value Error',
    GetRestrictionSuccess = '[Google] Get Restriction Success',
    GetRestrictionError = '[Google] Get Restriction Error',
    GetKeyValueSuccess = '[Google] Get Key Value Success',
    GetKeyValueError = '[Google] Get Key Value Error',
    GetExternalSegmentsSuccess = '[Google] Get External Segments Success',
    GetExternalSegmentsError = '[Google] Get External Segments Error',
}

export enum IntegrationsGoogleAdManagerHttpActionTypes {
    GetGoogleJavascriptSetup = '[Google] Get Google Javascript Setup',
    GetSelectedGoogleKeys = '[Google] Get Selected Google Keys',
    RequestDemo = '[Google] Request Demo',
    GetDomainRestrictions = '[Google] Get Domain Restrictions',
    GetSelectedDomainRestriction = '[Google] Get Selected Domain Restriction',
    DeleteDomainRestriction = '[Google] Delete Domain Restriction',
    EditDomainRestriction = '[Google] Edit Domain Restriction',
    CreateDomainRestriction = '[Google] Create Domain Restriction',
    GetAllKeyValues = '[Google] Get All Key Values',
    GetSelectedKeyDetails = '[Google] Get Selected Key Value',
    DeleteKeyValue = '[Google] Delete Key Value',
    EditKeyDetails = '[Google] Edit Key Value',
    CreateNewKeyValue = '[Google] Create New Key Value',
    GetRestriction = '[Google] Get Restriction',
    GetKeyValue = '[Google] Get Key Value',
    GetExternalSegments = '[Google] Get External Segments',
}

// ===== HTTP =====
export const GetGoogleJavascriptSetup = createAction(IntegrationsGoogleAdManagerHttpActionTypes.GetGoogleJavascriptSetup);
export const RequestDemo = createAction(IntegrationsGoogleAdManagerHttpActionTypes.RequestDemo);
export const GetDomainRestrictions = createAction(IntegrationsGoogleAdManagerHttpActionTypes.GetDomainRestrictions);
export const GetSelectedDomainRestriction = createAction(IntegrationsGoogleAdManagerHttpActionTypes.GetSelectedDomainRestriction, props<{ payload: { groupId: number } }>());
export const DeleteDomainRestriction = createAction(IntegrationsGoogleAdManagerHttpActionTypes.DeleteDomainRestriction, props<{ payload: { groupId: number } }>());
export const CreateDomainRestriction = createAction(IntegrationsGoogleAdManagerHttpActionTypes.CreateDomainRestriction, props<{ payload: { name: string, restrictions: Array<GoogleAdManagerRestriction> } }>());
export const EditDomainRestriction = createAction(IntegrationsGoogleAdManagerHttpActionTypes.EditDomainRestriction, props<{ payload: { groupId: number, name: string, restrictions: Array<GoogleAdManagerRestriction> } }>());
export const GetAllKeyValues = createAction(IntegrationsGoogleAdManagerHttpActionTypes.GetAllKeyValues);
export const GetSelectedKeyDetails = createAction(IntegrationsGoogleAdManagerHttpActionTypes.GetSelectedKeyDetails, props<{ payload: { id: number } }>());
export const DeleteKeyValue = createAction(IntegrationsGoogleAdManagerHttpActionTypes.DeleteKeyValue, props<{ payload: { id: number } }>());
export const EditKeyDetails = createAction(IntegrationsGoogleAdManagerHttpActionTypes.EditKeyDetails, props<{ payload: { id: number, name: string, newKeyName: string } }>());
export const CreateNewKeyValue = createAction(IntegrationsGoogleAdManagerHttpActionTypes.CreateNewKeyValue, props<{ payload: { name: string, criteriaId: number, keyValue: string, typeCode: number } }>());
export const GetRestriction = createAction(IntegrationsGoogleAdManagerHttpActionTypes.GetRestriction, props<{ payload: { id: number } }>());
export const GetKeyValue = createAction(IntegrationsGoogleAdManagerHttpActionTypes.GetKeyValue, props<{ payload: { id: number } }>());
export const GetExternalSegments = createAction(IntegrationsGoogleAdManagerHttpActionTypes.GetExternalSegments, props<{ payload: { typeId: number } }>());

// ===== INNER =====
export const GetGoogleJavascriptSetupSuccess = createAction(IntegrationsGoogleAdManagerInnerActionTypes.GetGoogleJavascriptSetupSuccess, props<{ payload: ResponseWrapper<JavascriptSetup> }>());
export const GetGoogleJavascriptSetupError = createAction(IntegrationsGoogleAdManagerInnerActionTypes.GetGoogleJavascriptSetupError, props<{ payload: BasicResponse }>());
export const RequestDemoSuccess = createAction(IntegrationsGoogleAdManagerInnerActionTypes.RequestDemoSuccess);
export const RequestDemoError = createAction(IntegrationsGoogleAdManagerInnerActionTypes.RequestDemoError, props<{ payload: BasicResponse }>());
export const GetDomainRestrictionsSuccess = createAction(IntegrationsGoogleAdManagerInnerActionTypes.GetDomainRestrictionsSuccess, props<{ payload: ResponseWrapper<GoogleAdManagerDomainSetupResponse> }>());
export const GetDomainRestrictionsError = createAction(IntegrationsGoogleAdManagerInnerActionTypes.GetDomainRestrictionsError, props<{ payload: BasicResponse }>());
export const GetSelectedDomainRestrictionSuccess = createAction(IntegrationsGoogleAdManagerInnerActionTypes.GetSelectedDomainRestrictionSuccess, props<{ payload: ResponseWrapper<GoogleAdManagerDomainGroupItem> }>());
export const GetSelectedDomainRestrictionError = createAction(IntegrationsGoogleAdManagerInnerActionTypes.GetSelectedDomainRestrictionError, props<{ payload: BasicResponse }>());
export const DeleteDomainRestrictionSuccess = createAction(IntegrationsGoogleAdManagerInnerActionTypes.DeleteDomainRestrictionSuccess);
export const DeleteDomainRestrictionError = createAction(IntegrationsGoogleAdManagerInnerActionTypes.DeleteDomainRestrictionError, props<{ payload: BasicResponse }>());
export const CreateDomainRestrictionSuccess = createAction(IntegrationsGoogleAdManagerInnerActionTypes.CreateDomainRestrictionSuccess, props<{ payload: ResponseWrapper<GoogleAdManagerDomainGroupItem> }>());
export const CreateDomainRestrictionError = createAction(IntegrationsGoogleAdManagerInnerActionTypes.CreateDomainRestrictionError, props<{ payload: BasicResponse }>());
export const EditDomainRestrictionSuccess = createAction(IntegrationsGoogleAdManagerInnerActionTypes.EditDomainRestrictionSuccess, props<{ payload: ResponseWrapper<GoogleAdManagerDomainGroupItem> }>());
export const EditDomainRestrictionError = createAction(IntegrationsGoogleAdManagerInnerActionTypes.EditDomainRestrictionError, props<{ payload: BasicResponse }>());
export const GetAllKeyValuesSuccess = createAction(IntegrationsGoogleAdManagerInnerActionTypes.GetAllKeyValuesSuccess, props<{ payload: ResponseWrapper<GoogleAdManagerExistingKeyResponse> }>());
export const GetAllKeyValuesError = createAction(IntegrationsGoogleAdManagerInnerActionTypes.GetAllKeyValuesError, props<{ payload: BasicResponse }>());
export const GetSelectedKeyDetailsSuccess = createAction(IntegrationsGoogleAdManagerInnerActionTypes.GetSelectedKeyDetailsSuccess, props<{ payload: ResponseWrapper<GoogleAdManagerExistingKeyResponse> }>());
export const GetSelectedKeyDetailsError = createAction(IntegrationsGoogleAdManagerInnerActionTypes.GetSelectedKeyDetailsError, props<{ payload: BasicResponse }>());
export const DeleteKeyValueSuccess = createAction(IntegrationsGoogleAdManagerInnerActionTypes.DeleteKeyValueSuccess);
export const DeleteKeyValueError = createAction(IntegrationsGoogleAdManagerInnerActionTypes.DeleteKeyValueError, props<{ payload: BasicResponse }>());
export const EditKeyDetailsSuccess = createAction(IntegrationsGoogleAdManagerInnerActionTypes.EditKeyDetailsSuccess, props<{ payload: ResponseWrapper<GoogleAdManagerExistingKeyResponse> }>());
export const EditKeyDetailsError = createAction(IntegrationsGoogleAdManagerInnerActionTypes.EditKeyDetailsError, props<{ payload: BasicResponse }>());
export const CreateNewKeyValueSuccess = createAction(IntegrationsGoogleAdManagerInnerActionTypes.CreateNewKeyValueSuccess, props<{ payload: ResponseWrapper<GoogleAdManagerExistingKeyResponse> }>());
export const CreateNewKeyValueError = createAction(IntegrationsGoogleAdManagerInnerActionTypes.CreateNewKeyValueError, props<{ payload: BasicResponse }>());
export const GetRestrictionSuccess = createAction(IntegrationsGoogleAdManagerInnerActionTypes.GetRestrictionSuccess, props<{ payload: ResponseWrapper<GoogleAdManagerDomainGroupItem[]> }>());
export const GetRestrictionError = createAction(IntegrationsGoogleAdManagerInnerActionTypes.GetRestrictionError, props<{ payload: BasicResponse }>());
export const GetKeyValueSuccess = createAction(IntegrationsGoogleAdManagerInnerActionTypes.GetKeyValueSuccess, props<{ payload: ResponseWrapper<GoogleAdManagerExistingValuesItem[]> }>());
export const GetKeyValueError = createAction(IntegrationsGoogleAdManagerInnerActionTypes.GetKeyValueError, props<{ payload: BasicResponse }>());
export const GetExternalSegmentsSuccess = createAction(IntegrationsGoogleAdManagerInnerActionTypes.GetExternalSegmentsSuccess, props<{ payload: ResponseWrapper<GoogleAdManagerExternalSegmentDetailsResponse> }>());
export const GetExternalSegmentsError = createAction(IntegrationsGoogleAdManagerInnerActionTypes.GetExternalSegmentsError, props<{ payload: BasicResponse }>());