import { createAction, props } from '@ngrx/store';
import { BasicResponse, ResponseWrapper } from 'common';
import { BlueconicData, SaveBlueconicDataReq } from './blueconic.interface';

export enum IntegrationsBlueconicInnerActionTypes {
    GetBlueconicDataSuccess = '[Blueconic] Get Blueconic Data Success',
    GetBlueconicDataError = '[Blueconic] Get Blueconic Data Error',
    SaveBlueconicDataSuccess = '[Blueconic] Save Blueconic Data Success',
    SaveBlueconicDataError = '[Blueconic] Save Blueconic Data Error',
}

export enum IntegrationsBlueconicHttpActionTypes {
    GetBlueconicData = '[Blueconic] Get Blueconic Data',
    SaveBlueconicData = '[Blueconic] Save Blueconic Data',
}

// ===== HTTP =====
export const GetBlueconicData = createAction(IntegrationsBlueconicHttpActionTypes.GetBlueconicData);
export const SaveBlueconicData = createAction(IntegrationsBlueconicHttpActionTypes.SaveBlueconicData, props<{ payload: SaveBlueconicDataReq }>());

// ===== INNER =====
export const GetBlueconicDataSuccess = createAction(IntegrationsBlueconicInnerActionTypes.GetBlueconicDataSuccess, props<{ payload: ResponseWrapper<BlueconicData> }>());
export const GetBlueconicDataError = createAction(IntegrationsBlueconicInnerActionTypes.GetBlueconicDataError, props<{ payload: BasicResponse }>());
export const SaveBlueconicDataSuccess = createAction(IntegrationsBlueconicInnerActionTypes.SaveBlueconicDataSuccess, props<{ payload: ResponseWrapper<BlueconicData> }>());
export const SaveBlueconicDataError = createAction(IntegrationsBlueconicInnerActionTypes.SaveBlueconicDataError, props<{ payload: BasicResponse }>());
