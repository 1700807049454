import { IAppState } from 'common';
import { IntegrationsState } from '@integrations/state/interfaces';
import { createSelector } from '@ngrx/store';

interface AppState {
    appState: IAppState,
    integrationsState: IntegrationsState
}

export const shopifyState = (state: AppState) => state.integrationsState?.shopify;

export const getShopifyStores = createSelector(
    shopifyState, state => state.shopifyStores
);

export const getShopifyStore = createSelector(
    shopifyState, state => state.shopifyStore
);

export const getShopifyStoreSave = createSelector(
    shopifyState, state => state.shopifyStoreSave
);

export const getMessageTypes = createSelector(
    shopifyState, state => state.messageTypes
);

export const getDeploymentTypes = createSelector(
    shopifyState, state => state.deploymentTypes
);

export const getProducts = createSelector(
    shopifyState, state => state.products
);

export const getFileList = createSelector(
    shopifyState, state => state.fileList
);

export const getFileListCount = createSelector(
    shopifyState, state => state.fileListCount
);

export const getStatuses = createSelector(
    shopifyState, state => state.statuses
);

export const getFileDetail = createSelector(
    shopifyState, state => state.fileDetail
);

export const getTemplate = createSelector(
    shopifyState, state => state.template
);
