import { createAction, props } from '@ngrx/store';
import { BasicResponse, ResponseWrapper } from 'common';
import { FacebookPixelAudience, FacebookPixelEventDetail, FacebookPixelEventSchema, FacebookPixelSaveReq, FacebookPixelsSite, GetSitePixelsData, OlyticsSite, SitesRes, UpdateFacebookPixelAudienceReq, UpdateFacebookPixelSettingsReq, UpdateFacebookPixelUrlsReq } from './meta-events-manager.interface';

export enum IntegrationsMetaEventsManagerInnerActionTypes {
    GetMetaEventsSitesSuccess = '[Meta Events] Get Meta Events Sites Success',
    GetMetaEventsSitesError = '[Meta Events] Get Meta Events Sites Error',
    GetMetaEventsPreviousPixelsSuccess = '[Meta Events] Get Meta Events Previous Pixels Success',
    GetMetaEventsPreviousPixelsError = '[Meta Events] Get Meta Events Previous Pixels Error',
    CreateSiteSuccess = '[Meta Events] Create Site Success',
    CreateSiteError = '[Meta Events] Create Site Error',
    ViewSiteReportSuccess = '[Meta Events] View Site Report Success',
    ViewSiteReportError = '[Meta Events] View Site Report Error',
    UpdatePixelStatusSuccess = '[Meta Events] Restore Archived Pixel Success',
    UpdatePixelStatusError = '[Meta Events] Restore Archived Pixel Error',
    LoadFacebookPixelSuccess = '[Facebook Pixel] Load Facebook Pixel Success',
    LoadFacebookPixelError = '[Facebook Pixel] Load Facebook Pixel Error',
    AddAbQueryToPixelEventSuccess = '[Meta Events] Add Ab Query To Pixel Event Success',
    AddAbQueryToPixelEventError = '[Meta Events] Add Ab Query To Pixel Event Error',
    DeleteAbQueryFromPixelEventError = '[Meta Events] Delete Ab Query From Pixel Event Error',
    ViewPixelEventReportSuccess = '[Meta Events] View Pixel Event Report Success',
    ViewPixelEventReportError = '[Meta Events] View Pixel Event Report Error',
    GetSitePixelsSuccess = '[Meta Events] Get Site Pixels Success',
    GetSitePixelsError = '[Meta Events] Get Site Pixels Error',
    GetSiteArchivedPixelsSuccess = '[Meta Events] Get Site Archived Pixels Success',
    GetSiteArchivedPixelsError = '[Meta Events] Get Site Archived Pixels Error',
    SaveFacebookPixelSuccess = '[Facebook Pixels] Save Facebook Pixel Success',
    SaveFacebookPixelError = '[Facebook Pixels] Save Facebook Pixel Error',
    UpdateFacebookPixelSettingsSuccess = '[Facebook Pixels] Update Facebook Pixel Settings Success',
    UpdateFacebookPixelSettingsError = '[Facebook Pixels] Update Facebook Pixel Settings Error',
    UpdateFacebookPixelUrlsSuccess = '[Facebook Pixels] Update Facebook Pixel Urls Success',
    UpdateFacebookPixelUrlsError = '[Facebook Pixels] Update Facebook Pixel Urls Error',
    UpdateFacebookPixelAudienceSuccess = '[Facebook Pixels] Update Facebook Pixel Audience Success',
    UpdateFacebookPixelAudienceError = '[Facebook Pixels] Update Facebook Pixel Audience Error',
    DeletePixelAudienceSuccess = '[Facebook Pixels] Delete Pixel Audience Success',
    DeletePixelAudienceError = '[Facebook Pixels] Delete Pixel Audience Error',
}

export enum IntegrationsMetaEventsManagerHttpActionTypes {
    GetMetaEventsSites = '[Meta Events] Get Meta Events Sites',
    GetMetaEventsPreviousPixels = '[Meta Events] Get Meta Events Previous Pixels',
    CreateSite = '[Meta Events] Create Site',
    ViewSiteReport = '[Meta Events] View Site Report',
    UpdatePixelStatus = '[Meta Events] Restore Archived Pixel',
    AddAbQueryToPixelEvent = '[Meta Events] Add Ab Query To Pixel Event',
    DeleteAbQueryFromPixelEvent = '[Meta Events] Delete Ab Query From Pixel Event',
    ViewPixelEventReport = '[Meta Events] View Pixel Event Report',
    GetSitePixels = '[Meta Events] Get Site Pixels',
    GetSiteArchivedPixels = '[Meta Events] Get Site Archived Pixels',
    LoadFacebookPixel = '[Facebook Pixels] Load Facebook Pixel',
    SaveFacebookPixel = '[Facebook Pixels] Save Facebook Pixel',
    UpdateFacebookPixelSettings = '[Facebook Pixels] Update Facebook Pixel Settings',
    UpdateFacebookPixelUrls = '[Facebook Pixels] Update Facebook Pixel Urls',
    UpdateFacebookPixelAudience = '[Facebook Pixels] Update Facebook Pixel Audience',
    SetCurrentPixelState = '[Facebook Pixels] Set Current Pixel State',
    DeletePixelAudience = '[Facebook Pixels] Delete Pixel Audience',
}

// ===== HTTP =====
export const GetMetaEventsSites = createAction(IntegrationsMetaEventsManagerHttpActionTypes.GetMetaEventsSites, props<{ payload: { offset?: number, numResult?: number, order?: string, sortBy?: string, isArchived?: boolean } }>());
export const GetMetaEventsPreviousPixels = createAction(IntegrationsMetaEventsManagerHttpActionTypes.GetMetaEventsPreviousPixels);
export const CreateSite = createAction(IntegrationsMetaEventsManagerHttpActionTypes.CreateSite, props<{ payload: { siteDomain: string, olyticsSiteId: number } }>());
export const ViewSiteReport = createAction(IntegrationsMetaEventsManagerHttpActionTypes.ViewSiteReport, props<{ payload: { olyticsSiteId: number } }>());
export const UpdatePixelStatus = createAction(IntegrationsMetaEventsManagerHttpActionTypes.UpdatePixelStatus, props<{ payload: { facebookPixelSegmentId: number, action: string, olyticsSiteId?: number, isArchived?: boolean } }>());
export const LoadFacebookPixel = createAction(IntegrationsMetaEventsManagerHttpActionTypes.LoadFacebookPixel, props<{ payload: { facebookPixelSegmentId: number } }>());
export const AddAbQueryToPixelEvent = createAction(IntegrationsMetaEventsManagerHttpActionTypes.AddAbQueryToPixelEvent, props<{ payload: { facebookPixelSegmentId: number, selectionCriteriaId: number, audienceName: string } }>());
export const DeleteAbQueryFromPixelEvent = createAction(IntegrationsMetaEventsManagerHttpActionTypes.DeleteAbQueryFromPixelEvent, props<{ payload: { facebookPixelSegmentId: number, selectionCriteriaId: number } }>());
export const ViewPixelEventReport = createAction(IntegrationsMetaEventsManagerHttpActionTypes.ViewPixelEventReport, props<{ payload: { facebookPixelSegmentId: number } }>());
export const GetSitePixels = createAction(IntegrationsMetaEventsManagerHttpActionTypes.GetSitePixels, props<{ payload: { olyticsSiteId: number, isArchived?: boolean } }>());
export const GetSiteArchivedPixels = createAction(IntegrationsMetaEventsManagerHttpActionTypes.GetSiteArchivedPixels, props<{ payload: { olyticsSiteId: number } }>());
export const SaveFacebookPixel = createAction(IntegrationsMetaEventsManagerHttpActionTypes.SaveFacebookPixel, props<{ payload: FacebookPixelSaveReq, activate?: boolean }>());
export const UpdateFacebookPixelSettings = createAction(IntegrationsMetaEventsManagerHttpActionTypes.UpdateFacebookPixelSettings, props<{ payload: UpdateFacebookPixelSettingsReq }>());
export const UpdateFacebookPixelUrls = createAction(IntegrationsMetaEventsManagerHttpActionTypes.UpdateFacebookPixelUrls, props<{ payload: UpdateFacebookPixelUrlsReq }>());
export const UpdateFacebookPixelAudience = createAction(IntegrationsMetaEventsManagerHttpActionTypes.UpdateFacebookPixelAudience, props<{ payload: UpdateFacebookPixelAudienceReq }>());
export const SetCurrentPixelState = createAction(IntegrationsMetaEventsManagerHttpActionTypes.SetCurrentPixelState, props<{ payload: { typeCode?: number; audienceSaved?: boolean; urlsSaved?: boolean; settingsSaved?: boolean } }>());
export const DeletePixelAudience = createAction(IntegrationsMetaEventsManagerHttpActionTypes.DeletePixelAudience, props<{ payload: { facebookPixelSegmentAudienceId: number } }>());

// ===== INNER =====
export const GetMetaEventsSitesSuccess = createAction(IntegrationsMetaEventsManagerInnerActionTypes.GetMetaEventsSitesSuccess, props<{ payload: ResponseWrapper<SitesRes> }>());
export const GetMetaEventsSitesError = createAction(IntegrationsMetaEventsManagerInnerActionTypes.GetMetaEventsSitesError, props<{ payload: BasicResponse }>());
export const GetMetaEventsPreviousPixelsSuccess = createAction(IntegrationsMetaEventsManagerInnerActionTypes.GetMetaEventsPreviousPixelsSuccess, props<{ payload: ResponseWrapper<Array<string>> }>());
export const GetMetaEventsPreviousPixelsError = createAction(IntegrationsMetaEventsManagerInnerActionTypes.GetMetaEventsPreviousPixelsError, props<{ payload: BasicResponse }>());
export const CreateSiteSuccess = createAction(IntegrationsMetaEventsManagerInnerActionTypes.CreateSiteSuccess, props<{ payload: ResponseWrapper<OlyticsSite> }>());
export const CreateSiteError = createAction(IntegrationsMetaEventsManagerInnerActionTypes.CreateSiteError, props<{ payload: BasicResponse }>());
export const ViewSiteReportSuccess = createAction(IntegrationsMetaEventsManagerInnerActionTypes.ViewSiteReportSuccess, props<{ payload: ResponseWrapper<FacebookPixelsSite> }>());
export const ViewSiteReportError = createAction(IntegrationsMetaEventsManagerInnerActionTypes.ViewSiteReportError, props<{ payload: BasicResponse }>());
export const UpdatePixelStatusSuccess = createAction(IntegrationsMetaEventsManagerInnerActionTypes.UpdatePixelStatusSuccess, props<{ payload: ResponseWrapper<BasicResponse> }>());
export const UpdatePixelStatusError = createAction(IntegrationsMetaEventsManagerInnerActionTypes.UpdatePixelStatusError, props<{ payload: BasicResponse }>());
export const LoadFacebookPixelSuccess = createAction(IntegrationsMetaEventsManagerInnerActionTypes.LoadFacebookPixelSuccess, props<{ payload: ResponseWrapper<FacebookPixelEventDetail> }>());
export const LoadFacebookPixelError = createAction(IntegrationsMetaEventsManagerInnerActionTypes.LoadFacebookPixelError, props<{ payload: BasicResponse }>());
export const AddAbQueryToPixelEventSuccess = createAction(IntegrationsMetaEventsManagerInnerActionTypes.AddAbQueryToPixelEventSuccess, props<{ payload: ResponseWrapper<FacebookPixelAudience> }>());
export const AddAbQueryToPixelEventError = createAction(IntegrationsMetaEventsManagerInnerActionTypes.AddAbQueryToPixelEventError, props<{ payload: BasicResponse }>());
export const DeleteAbQueryFromPixelEventError = createAction(IntegrationsMetaEventsManagerInnerActionTypes.DeleteAbQueryFromPixelEventError, props<{ payload: BasicResponse }>());
export const ViewPixelEventReportSuccess = createAction(IntegrationsMetaEventsManagerInnerActionTypes.ViewPixelEventReportSuccess, props<{ payload: ResponseWrapper<FacebookPixelsSite> }>());
export const ViewPixelEventReportError = createAction(IntegrationsMetaEventsManagerInnerActionTypes.ViewPixelEventReportError, props<{ payload: BasicResponse }>());
export const GetSitePixelsSuccess = createAction(IntegrationsMetaEventsManagerInnerActionTypes.GetSitePixelsSuccess, props<{ payload: ResponseWrapper<GetSitePixelsData> }>());
export const GetSitePixelsError = createAction(IntegrationsMetaEventsManagerInnerActionTypes.GetSitePixelsError, props<{ payload: { olyticsSiteId: number; isArchived: boolean } }>());
export const GetSiteArchivedPixelsSuccess = createAction(IntegrationsMetaEventsManagerInnerActionTypes.GetSiteArchivedPixelsSuccess, props<{ payload: ResponseWrapper<FacebookPixelEventSchema[]> }>());
export const GetSiteArchivedPixelsError = createAction(IntegrationsMetaEventsManagerInnerActionTypes.GetSiteArchivedPixelsError, props<{ payload: { olyticsSiteId: number } }>());
export const SaveFacebookPixelSuccess = createAction(IntegrationsMetaEventsManagerInnerActionTypes.SaveFacebookPixelSuccess, props<{ payload: ResponseWrapper<FacebookPixelEventDetail> }>());
export const SaveFacebookPixelError = createAction(IntegrationsMetaEventsManagerInnerActionTypes.SaveFacebookPixelError, props<{ payload: BasicResponse }>());
export const UpdateFacebookPixelSettingsSuccess = createAction(IntegrationsMetaEventsManagerInnerActionTypes.UpdateFacebookPixelSettingsSuccess, props<{ payload: ResponseWrapper<BasicResponse> }>());
export const UpdateFacebookPixelSettingsError = createAction(IntegrationsMetaEventsManagerInnerActionTypes.UpdateFacebookPixelSettingsError, props<{ payload: BasicResponse }>());
export const UpdateFacebookPixelUrlsSuccess = createAction(IntegrationsMetaEventsManagerInnerActionTypes.UpdateFacebookPixelUrlsSuccess, props<{ payload: ResponseWrapper<BasicResponse> }>());
export const UpdateFacebookPixelUrlsError = createAction(IntegrationsMetaEventsManagerInnerActionTypes.UpdateFacebookPixelUrlsError, props<{ payload: BasicResponse }>());
export const UpdateFacebookPixelAudienceSuccess = createAction(IntegrationsMetaEventsManagerInnerActionTypes.UpdateFacebookPixelAudienceSuccess, props<{ payload: ResponseWrapper<BasicResponse> }>());
export const UpdateFacebookPixelAudienceError = createAction(IntegrationsMetaEventsManagerInnerActionTypes.UpdateFacebookPixelAudienceError, props<{ payload: BasicResponse }>());
export const DeletePixelAudienceSuccess = createAction(IntegrationsMetaEventsManagerInnerActionTypes.DeletePixelAudienceSuccess, props<{ payload: ResponseWrapper<{res: BasicResponse; facebookPixelSegmentAudienceId: number}> }>());
export const DeletePixelAudienceError = createAction(IntegrationsMetaEventsManagerInnerActionTypes.DeletePixelAudienceError, props<{ payload: BasicResponse }>());