import { createAction, props } from '@ngrx/store';
import { BasicResponse, ResponseWrapper } from 'common';
import { AdrollAccessInfo, AdrollAudienceSyncData, AdrollEBASiteReport, AdrollPixelSegment, AdrollPixelSegmentDetail, AdrollSegmentAudienceI, AdrollSitesRes, SaveAdrollAudienceReq, UpdateAudienceSyncAudienceTargetingReq, UpdateAudienceSyncSettingsReq, UpdateAudienceSyncUrlsReq } from './adroll-event-based.interface';
import { AdrollAuthorization } from '../adroll/adroll.interface';
import { HttpErrorResponse } from '@angular/common/http';

export enum IntegrationsAdrollEventBasedInnerActionTypes {
    GetAdrollSitesSuccess = '[Adroll Manager] Get Adroll Sites Success',
    GetAdrollSitesError = '[Adroll Manager] Get Adroll Sites Error',
    GetAudienceSyncsSuccess = '[Adroll Manager] Get Audience Syncs Success',
    GetAudienceSyncsError = '[Adroll Manager] Get Audience Syncs Error',
    GetArchivedAudienceSyncsSuccess = '[Adroll Manager] Get Archived Audience Syncs Success',
    GetArchivedAudienceSyncsError = '[Adroll Manager] Get Archived Audience Syncs Error',
    LoadAdrollAudienceSyncSuccess = '[Adroll Manager] Load Adroll Audience Sync Success',
    LoadAdrollAudienceSyncError = '[Adroll Manager] Load Adroll Audience Sync Error',
    DeleteAdrollAudiecneSyncSuccess = '[Adroll Manager] Delete Adroll Audience Sync Success',
    DeleteAdrollAudiecneSyncError = '[Adroll Manager] Delete Adroll Audience Sync Error',
    SaveAdrollAudienceSyncSuccess = '[Adroll Manager] Save Adroll Audience Sync Success',
    SaveAdrollAudienceSyncError = '[Adroll Manager] Save Adroll Audience Sync Error',
    UpdateAdrollAudienceSyncSettingsSuccess = '[Adroll Manager] Update Adroll Audience Sync Settings Success',
    UpdateAdrollAudienceSyncSettingsError = '[Adroll Manager] Update Adroll Audience Sync Settings Error',
    UpdateAdrollAudienceSyncUrlsSuccess = '[Adroll Manager] Update Adroll Audience Sync Urls Success',
    UpdateAdrollAudienceSyncUrlsError = '[Adroll Manager] Update Adroll Audience Sync Urls Error',
    UpdateAdrollAudienceSyncAudienceSuccess = '[Adroll Manager] Update Adroll Audience Sync Audience Success',
    UpdateAdrollAudienceSyncAudienceError = '[Adroll Manager] Update Adroll Audience Sync Audience Error',
    GetAudienceSyncAuthInfoSuccess = '[Adroll Manager] Get Audience Sync Auth Info Success',
    GetAudienceSyncAuthInfoError = '[Adroll Manager] Get Audience Sync Auth Info Error',
    AddAbQueryToAudienceSyncSuccess = '[Adroll Manager] Add Ab Query To Audience Sync Success',
    AddAbQueryToAudienceSyncError = '[Adroll Manager] Add Ab Query To Audience Sync Error',
    DeleteAbQueryFromAudienceSyncSuccess = '[Adroll Manager] Delete Ab Query From Audience Sync Success',
    DeleteAbQueryFromAudienceSyncError = '[Adroll Manager] Delete Ab Query From Audience Sync Error',
    GetAudienceSyncAccessTokenSuccess = '[Adroll Manager] Get Audience Sync Access Token Success',
    GetAudienceSyncAccessTokenError = '[Adroll Manager] Get Audience Sync Access Token Error',
    ArchiveAdrollAudienceSyncSuccess = '[Adroll Manager] Archive Adroll Audience Sync Success',
    ArchiveAdrollAudienceSyncError = '[Adroll Manager] Archive Adroll Audience Sync Error',
    RestoreAdrollPixelEventSuccess = '[Adroll Manager] Restore Adroll Pixel Event Success',
    RestoreAdrollPixelEventError = '[Adroll Manager] Restore Adroll Pixel Event Error',
    ViewAudienceSyncReportSuccess = '[Adroll Manager] View Audience Sync Report Success',
    ViewAudienceSyncReportError = '[Adroll Manager] View Audience Sync Report Error',
    CreateAdrollSiteSuccess = '[Adroll Manager] Create Adroll Site Success',
    CreateAdrollSiteError = '[Adroll Manager] Create Adroll Site Error',
    DeleteAdrollSiteError = '[Adroll Manager] Delete Adroll Site Error',
    ViewAdrollPixelSegmentReportSuccess = '[Adroll Manager] View Adroll Pixel Segment Report Success',
    ViewAdrollPixelSegmentReportError = '[Adroll Manager] View Adroll Pixel Segment Report Error',
}

export enum IntegrationsAdrollEventBasedHttpActionTypes {
    GetAdrollSites = '[Adroll Manager] Get Adroll Sites',
    GetAudienceSyncs = '[Adroll Manager] Get Audience Syncs',
    GetArchivedAudienceSyncs = '[Adroll Manager] Get Archived Audience Syncs',
    LoadAdrollAudienceSync = '[Adroll Manager] Load Adroll Audience Sync',
    DeleteAdrollAudienceSync = '[Adroll Manager] Delete Adroll Audience Sync',
    SaveAdrollAudienceSync = '[Adroll Manager] Save Adroll Audience Sync',
    UpdateAdrollAudienceSyncSettings = '[Adroll Manager] Update Adroll Audience Sync Settings',
    UpdateAdrollAudienceSyncUrls = '[Adroll Manager] Update Adroll Audience Sync Urls',
    UpdateAdrollAudienceSyncAudience = '[Adroll Manager] Update Adroll Audience Sync Audience',
    SetCurrentAdrollAudienceSyncState = '[Adroll Manager] Set Current Adroll Audience Sync State',
    AddAbQueryToAudienceSync = '[Adroll Manager] Add Ab Query To Audience Sync',
    DeleteAbQueryFromAudienceSync = '[Adroll Manager] Delete Ab Query From Audience Sync',
    GetAudienceSyncAuthInfo = '[Adroll Manager] Get Audience Sync Auth Info',
    GetAudienceSyncAccessToken = '[Adroll Manager] Get Audience Sync Access Token',
    ArchiveAdrollAudienceSync = '[Adroll Manager] Archive Adroll Audience Sync',
    RestoreAdrollPixelEvent = '[Adroll Manager] Restore Adroll Pixel Event',
    ViewAudienceSyncReport = '[Adroll Manager] View Audience Sync Report',
    CreateAdrollSite = '[Adroll Manager] Create Adroll Site',
    DeleteAdrollSite = '[Adroll Manager] Delete Adroll Site',
    ViewAdrollPixelSegmentReport = '[Adroll Manager] View Adroll Pixel Segment Report',
}

// ===== HTTP =====
export const GetAdrollSites = createAction(IntegrationsAdrollEventBasedHttpActionTypes.GetAdrollSites, props<{ payload: { offset?: number, numResults?: number, sortBy?: string, order?: string, isArchived?: boolean } }>());
export const GetAudienceSyncs = createAction(IntegrationsAdrollEventBasedHttpActionTypes.GetAudienceSyncs, props<{ payload: { olyticsSiteId: number, isArchived?: boolean, numResults?: number, offset?: number, sortBy?: string, order?: string } }>());
export const GetArchivedAudienceSyncs = createAction(IntegrationsAdrollEventBasedHttpActionTypes.GetArchivedAudienceSyncs, props<{ payload: { olyticsSiteId: number } }>());
export const LoadAdrollAudienceSync = createAction(IntegrationsAdrollEventBasedHttpActionTypes.LoadAdrollAudienceSync, props<{ payload: { adrollPixelSegmentId: number } }>());
export const DeleteAdrollAudiecneSync = createAction(IntegrationsAdrollEventBasedHttpActionTypes.DeleteAdrollAudienceSync, props<{ payload: { adrollPixelSegmentId: number, olyticsSiteId?: number } }>());
export const SaveAdrollAudienceSync = createAction(IntegrationsAdrollEventBasedHttpActionTypes.SaveAdrollAudienceSync, props<{ payload: { saveAdrollAudienceReq: SaveAdrollAudienceReq, accessToken: string, activate?: boolean, currentRoute?: string } }>());
export const UpdateAdrollAudienceSyncSettings = createAction(IntegrationsAdrollEventBasedHttpActionTypes.UpdateAdrollAudienceSyncSettings, props<{ payload: UpdateAudienceSyncSettingsReq }>());
export const UpdateAdrollAudienceSyncUrls = createAction(IntegrationsAdrollEventBasedHttpActionTypes.UpdateAdrollAudienceSyncUrls, props<{ payload: UpdateAudienceSyncUrlsReq }>());
export const UpdateAdrollAudienceSyncAudience = createAction(IntegrationsAdrollEventBasedHttpActionTypes.UpdateAdrollAudienceSyncAudience, props<{ payload: UpdateAudienceSyncAudienceTargetingReq }>());
export const GetAudienceSyncAuthInfo = createAction(IntegrationsAdrollEventBasedHttpActionTypes.GetAudienceSyncAuthInfo, props<{ payload: { redirectUrl: string } }>());
//eslint-disable-next-line @typescript-eslint/no-explicit-any
export const SetCurrentAdrollAudienceSyncState = createAction(IntegrationsAdrollEventBasedHttpActionTypes.SetCurrentAdrollAudienceSyncState, props<{ payload: any }>());
export const AddAbQueryToAudienceSync = createAction(IntegrationsAdrollEventBasedHttpActionTypes.AddAbQueryToAudienceSync, props<{ payload: { adrollPixelSegmentId: number, selectionCriteriaId: number, audienceName: string } }>());
export const DeleteAbQueryFromAudienceSync = createAction(IntegrationsAdrollEventBasedHttpActionTypes.DeleteAbQueryFromAudienceSync, props<{ payload: { adrollPixelSegmentAudienceId: number } }>());
export const GetAudienceSyncAccessToken = createAction(IntegrationsAdrollEventBasedHttpActionTypes.GetAudienceSyncAccessToken, props<{ payload: { code: string } }>());
export const ArchiveAdrollAudienceSync = createAction(IntegrationsAdrollEventBasedHttpActionTypes.ArchiveAdrollAudienceSync, props<{ payload: { adrollPixelSegmentId: number, olyticsSiteId?: number } }>());
export const RestoreAdrollPixelEvent = createAction(IntegrationsAdrollEventBasedHttpActionTypes.RestoreAdrollPixelEvent, props<{ payload: { adrollPixelSegmentId: number, searchText?: string, olyticsSiteId?: number } }>());
export const ViewAudienceSyncReport = createAction(IntegrationsAdrollEventBasedHttpActionTypes.ViewAudienceSyncReport, props<{ payload: { adrollPixelSegmentId: number } }>());
export const CreateAdrollSite = createAction(IntegrationsAdrollEventBasedHttpActionTypes.CreateAdrollSite, props<{ payload: { siteDomain: string, olyticsSiteId: number } }>());
export const DeleteAdrollSite = createAction(IntegrationsAdrollEventBasedHttpActionTypes.DeleteAdrollSite, props<{ payload: { olyticsSiteId: number } }>());
export const ViewAdrollPixelSegmentReport = createAction(IntegrationsAdrollEventBasedHttpActionTypes.ViewAdrollPixelSegmentReport, props<{ payload: { adrollPixelSegmentId: number } }>());

// ===== INNER =====
export const GetAdrollSitesSuccess = createAction(IntegrationsAdrollEventBasedInnerActionTypes.GetAdrollSitesSuccess, props<{ payload: ResponseWrapper<AdrollSitesRes> }>());
export const GetAdrollSitesError = createAction(IntegrationsAdrollEventBasedInnerActionTypes.GetAdrollSitesError, props<{ payload: BasicResponse }>());
export const GetAudienceSyncsSuccess = createAction(IntegrationsAdrollEventBasedInnerActionTypes.GetAudienceSyncsSuccess, props<{ payload: ResponseWrapper<AdrollAudienceSyncData> }>());
export const GetAudienceSyncsError = createAction(IntegrationsAdrollEventBasedInnerActionTypes.GetAudienceSyncsError, props<{ payload: { olyticsSiteId: number; isArchived: boolean; } }>());
export const GetArchivedAudienceSyncsSuccess = createAction(IntegrationsAdrollEventBasedInnerActionTypes.GetArchivedAudienceSyncsSuccess, props<{ payload: ResponseWrapper<AdrollPixelSegment[]> }>());
export const GetArchivedAudienceSyncsError = createAction(IntegrationsAdrollEventBasedInnerActionTypes.GetArchivedAudienceSyncsError, props<{ payload: HttpErrorResponse | BasicResponse }>());
export const LoadAdrollAudienceSyncSuccess = createAction(IntegrationsAdrollEventBasedInnerActionTypes.LoadAdrollAudienceSyncSuccess, props<{ payload: ResponseWrapper<AdrollPixelSegmentDetail> }>());
export const LoadAdrollAudienceSyncError = createAction(IntegrationsAdrollEventBasedInnerActionTypes.LoadAdrollAudienceSyncError, props<{ payload: BasicResponse }>());
export const DeleteAdrollAudienceSyncSuccess = createAction(IntegrationsAdrollEventBasedInnerActionTypes.DeleteAdrollAudiecneSyncSuccess, props<{ payload: BasicResponse }>());
export const DeleteAdrollAudienceSyncError = createAction(IntegrationsAdrollEventBasedInnerActionTypes.DeleteAdrollAudiecneSyncError, props<{ payload: BasicResponse }>());
export const SaveAdrollAudienceSyncSuccess = createAction(IntegrationsAdrollEventBasedInnerActionTypes.SaveAdrollAudienceSyncSuccess, props<{ payload: ResponseWrapper<AdrollPixelSegmentDetail> }>());
export const SaveAdrollAudienceSyncError = createAction(IntegrationsAdrollEventBasedInnerActionTypes.SaveAdrollAudienceSyncError, props<{ payload: BasicResponse }>());
export const UpdateAdrollAudienceSyncSettingsSuccess = createAction(IntegrationsAdrollEventBasedInnerActionTypes.UpdateAdrollAudienceSyncSettingsSuccess, props<{ payload: BasicResponse }>());
export const UpdateAdrollAudienceSyncSettingsError = createAction(IntegrationsAdrollEventBasedInnerActionTypes.UpdateAdrollAudienceSyncSettingsError, props<{ payload: BasicResponse }>());
export const UpdateAdrollAudienceSyncUrlsSuccess = createAction(IntegrationsAdrollEventBasedInnerActionTypes.UpdateAdrollAudienceSyncUrlsSuccess, props<{ payload: BasicResponse }>());
export const UpdateAdrollAudienceSyncUrlsError = createAction(IntegrationsAdrollEventBasedInnerActionTypes.UpdateAdrollAudienceSyncUrlsError, props<{ payload: BasicResponse }>());
export const UpdateAdrollAudienceSyncAudienceSuccess = createAction(IntegrationsAdrollEventBasedInnerActionTypes.UpdateAdrollAudienceSyncAudienceSuccess, props<{ payload: BasicResponse }>());
export const UpdateAdrollAudienceSyncAudienceError = createAction(IntegrationsAdrollEventBasedInnerActionTypes.UpdateAdrollAudienceSyncAudienceError, props<{ payload: BasicResponse }>());
export const GetAudienceSyncAuthInfoSuccess = createAction(IntegrationsAdrollEventBasedInnerActionTypes.GetAudienceSyncAuthInfoSuccess, props<{ payload: ResponseWrapper<AdrollAuthorization> }>());
export const GetAudienceSyncAuthInfoError = createAction(IntegrationsAdrollEventBasedInnerActionTypes.GetAudienceSyncAuthInfoError, props<{ payload: BasicResponse }>());
export const AddAbQueryToAudienceSyncSuccess = createAction(IntegrationsAdrollEventBasedInnerActionTypes.AddAbQueryToAudienceSyncSuccess, props<{ payload: ResponseWrapper<AdrollSegmentAudienceI> }>());
export const AddAbQueryToAudienceSyncError = createAction(IntegrationsAdrollEventBasedInnerActionTypes.AddAbQueryToAudienceSyncError, props<{ payload: BasicResponse }>());
export const DeleteAbQueryFromAudienceSyncSuccess = createAction(IntegrationsAdrollEventBasedInnerActionTypes.DeleteAbQueryFromAudienceSyncSuccess, props<{ payload: ResponseWrapper<{res: BasicResponse; adrollPixelSegmentAudienceId: number;}> }>());
export const DeleteAbQueryFromAudienceSyncError = createAction(IntegrationsAdrollEventBasedInnerActionTypes.DeleteAbQueryFromAudienceSyncError, props<{ payload: BasicResponse }>());
export const GetAudienceSyncAccessTokenSuccess = createAction(IntegrationsAdrollEventBasedInnerActionTypes.GetAudienceSyncAccessTokenSuccess, props<{ payload: ResponseWrapper<AdrollAccessInfo> }>());
export const GetAudienceSyncAccessTokenError = createAction(IntegrationsAdrollEventBasedInnerActionTypes.GetAudienceSyncAccessTokenError, props<{ payload: BasicResponse }>());
export const ArchiveAdrollAudienceSyncSuccess = createAction(IntegrationsAdrollEventBasedInnerActionTypes.ArchiveAdrollAudienceSyncSuccess, props<{ payload: BasicResponse }>());
export const ArchiveAdrollAudienceSyncError = createAction(IntegrationsAdrollEventBasedInnerActionTypes.ArchiveAdrollAudienceSyncError, props<{ payload: BasicResponse }>());
export const RestoreAdrollPixelEventSuccess = createAction(IntegrationsAdrollEventBasedInnerActionTypes.RestoreAdrollPixelEventSuccess, props<{ payload: BasicResponse }>());
export const RestoreAdrollPixelEventError = createAction(IntegrationsAdrollEventBasedInnerActionTypes.RestoreAdrollPixelEventError, props<{ payload: BasicResponse }>());
export const ViewAudienceSyncReportSuccess = createAction(IntegrationsAdrollEventBasedInnerActionTypes.ViewAudienceSyncReportSuccess, props<{ payload: ResponseWrapper<AdrollEBASiteReport> }>());
export const ViewAudienceSyncReportError = createAction(IntegrationsAdrollEventBasedInnerActionTypes.ViewAudienceSyncReportError, props<{ payload: BasicResponse }>());
export const CreateAdrollSiteSuccess = createAction(IntegrationsAdrollEventBasedInnerActionTypes.CreateAdrollSiteSuccess, props<{ payload: ResponseWrapper<BasicResponse> }>());
export const CreateAdrollSiteError = createAction(IntegrationsAdrollEventBasedInnerActionTypes.CreateAdrollSiteError, props<{ payload: BasicResponse }>());
export const DeleteAdrollSiteError = createAction(IntegrationsAdrollEventBasedInnerActionTypes.DeleteAdrollSiteError, props<{ payload: BasicResponse }>());
export const ViewAdrollPixelSegmentReportSuccess = createAction(IntegrationsAdrollEventBasedInnerActionTypes.ViewAdrollPixelSegmentReportSuccess, props<{ payload: ResponseWrapper<AdrollEBASiteReport> }>());
export const ViewAdrollPixelSegmentReportError = createAction(IntegrationsAdrollEventBasedInnerActionTypes.ViewAdrollPixelSegmentReportError, props<{ payload: BasicResponse }>());